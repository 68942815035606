import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import magazineRo from "../../images/rollovers/graphic-design/magazine-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/magazine/1.jpg'
import img2 from '../../images/graphic-design/magazine/2.jpg'
import img3 from '../../images/graphic-design/magazine/3.jpg'
import img4 from '../../images/graphic-design/magazine/4.jpg'
import img5 from '../../images/graphic-design/magazine/5.jpg'
import img6 from '../../images/graphic-design/magazine/6.jpg'
import img7 from '../../images/graphic-design/magazine/7.jpg'
import img8 from '../../images/graphic-design/magazine/8.jpg'
import img9 from '../../images/graphic-design/magazine/9.jpg'
import img10 from '../../images/graphic-design/magazine/10.jpg'
import img11 from '../../images/graphic-design/magazine/11.jpg'

export default function Magazine() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]
    return (
        <GalleryBackground title="Magazine" imgSrc={magazineRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}